.Year2022 {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%; 
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
}