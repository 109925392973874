.Year2024 {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#4b79a1, #283e51);
}
  
.Year2024-top {
  display: flex;
  padding: 10px 0;
}

.Year2024-actions {
  flex: 1;
}