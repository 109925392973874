.App {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: black;
}

.App-countdown {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -110px 0 0 0;
  height: 220px;
  text-align: center;
  color: white;
  font-family: Arial, serif;
  font-size: 100px;
  line-height: 227px;
  font-weight: bold;
}